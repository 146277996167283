import React, {useEffect, useState} from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { useQuery } from 'react-query';
import apiClient from '../../request/http-common';
import { Fragment } from 'react';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import {MiniChart } from "react-ts-tradingview-widgets";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import WalletButton from '../Wallet/WalletButton';
import { useSelector } from 'react-redux';
import WithdrawalButton from '../Withdrawal/WithdrawalButton';
import EarningAnalysis from '../Earning/EarningAnalysis';

const CoinMarket = () => {
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    const coins = useSelector((state) => state.coins.coins);
    const [coinSymbol, setCoinSymbol] = useState('BINANCE:BTCUSDT');
    const availableCoins = ['USDT', 'USDTERC20', 'USDTTRC20', 'XMR', 'TRX', 'BTC', 'LTC', 'BCH', 'DOGE', 'DASH', 'BNB'];

  return (
    <div>
      <Card sx={{height: "200px", marginTop: '65px', borderRadius: '15px', overflow: 'hidden'}}>
        <CardContent>
            <MiniChart symbol= {coinSymbol}
                height= {180}
                locale= "en"
                dateRange= "12M"
                colorTheme= "dark"
                trendLineColor= "rgba(6, 222, 156, 1)"
                underLineColor= "rgba(136, 241, 195, 0.16)"
                underLineBottomColor= "rgba(41, 98, 255, 0)"
                isTransparent= {true}
                autosize= {false}
                width="100%"/>
        </CardContent>
      </Card>

      <Card>
        <CardActions sx={{justifyContent: 'space-between'}}>

        <WalletButton/>        

        <WithdrawalButton/>

        </CardActions>
      </Card>

      <EarningAnalysis/>

        {/* {Object.keys(coins).length < 1 &&
        <CircularProgress/>
        }
        {Object.keys(coins).length > 0 && 

        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

            {Object.keys(coins).map((coin) =>(
              availableCoins.includes(coins[coin].symbol) &&
                <Fragment key={coins[coin].symbol}>
                <ListItem alignItems="flex-start" sx={{cursor: 'pointer'}}
                onClick={()=>setCoinSymbol('BINANCE:'+coins[coin].symbol+'USD')}
                >
          <ListItemAvatar>
            <Avatar alt="Coin Name"
            src={
              coin === 'TCN' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664451074/242cd1653295679.png' ||
              coin === 'BTC' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450857/btc-sw.png'||
              coin === 'LTC' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450859/ltc-sw.png'||
              coin === 'BCH' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450857/bch-sw.png'||
              coin === 'DOGE' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450857/doge-sw2.png'||
              coin === 'DASH' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664451267/ba8ea1653295812.png'||
              coin === 'USDTTRC20' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1693554232/usdttrc20.png'||
              coin === 'USDTERC20' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1693554186/usdterc20.png'||
              coin === 'TRX' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1693554250/trx.png'||
              coin === 'XMR' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1693554258/xmr.png'||
              coin === 'BNB' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664451323/ac2f71653295852.png'
            }
            />
        </ListItemAvatar>
                <ListItemText
                  primary={coins[coin].symbol}
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: 'inline' }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {coins[coin].name}
                      </Typography>
                    </React.Fragment>
                  }
                />
                <Box edge="end" sx={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center'}}>
                    <Typography mx={1}>
                    {formatPrice(coins[coin].price)}
                    </Typography>
                </Box>
                
              </ListItem>
              <Divider variant="inset" component="li" />
              </Fragment>
            ))}
        
        
  
      </List>
        
        } */}
      
      
    </div>
  )
}

export default CoinMarket
