import React, {useState} from 'react'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { Fragment } from 'react';
import Box from '@mui/material/Box';
import { useSelector } from 'react-redux';

const Markets = () => {
    const formatPrice = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);
    const coins = useSelector((state) => state.coins.coins);
    const [coinSymbol, setCoinSymbol] = useState('BINANCE:BTCUSDT');
    const availableCoins = ['BTC', 'LTC', 'BCH', 'DOGE', 'DASH', 'BNB', 'USDT'];

  return (
    <div>
       {Object.keys(coins).length > 0 && 

<List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

    {Object.keys(coins).map((coin) =>(
      availableCoins.includes(coins[coin].symbol) &&
        <Fragment key={coins[coin].symbol}>
        <ListItem alignItems="flex-start" sx={{cursor: 'pointer'}}
        onClick={()=>setCoinSymbol('BINANCE:'+coins[coin].symbol+'USD')}
        >
  <ListItemAvatar>
    <Avatar alt="Coin Name"
    src={
      coin === 'TCN' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664451074/242cd1653295679.png' ||
      coin === 'BTC' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450857/btc-sw.png'||
      coin === 'LTC' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450859/ltc-sw.png'||
      coin === 'BCH' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450857/bch-sw.png'||
      coin === 'DOGE' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664450857/doge-sw2.png'||
      coin === 'DASH' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664451267/ba8ea1653295812.png'||
      coin === 'BNB' && 'https://res.cloudinary.com/dzxyvdq14/image/upload/v1664451323/ac2f71653295852.png'
    }
    />
</ListItemAvatar>
        <ListItemText
          primary={coins[coin].symbol}
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                {coins[coin].name}
              </Typography>
            </React.Fragment>
          }
        />
        <Box edge="end" sx={{display: 'flex', justifyContent: 'space-between', alignContent: 'center', alignItems: 'center'}}>
            <Typography mx={1}>
            {formatPrice(coins[coin].price)}
            </Typography>
        </Box>
        
      </ListItem>
      <Divider variant="inset" component="li" />
      </Fragment>
    ))}



</List>

}
    </div>
  )
}

export default Markets
