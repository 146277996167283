import React, {useState, useEffect} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';

const CreatePlans = () => {
    const [plans, setplans] = useState(null);
    const { isLoading: isLoadingplans, refetch: getplans } = useQuery(
        "plans",
        async () => {
          return await apiClient.get("/api/plans");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setplans(res.data.plans);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      
    const dispatch = useDispatch();
    
    const initialLogin = {
        name : ''	,
        max_amount : ''	,
        min_amount : ''	,
        bonus_per_downline : ''	,
        amount_per_hour: '',
        number_of_downlines : ''	,
        total_earning : ''	,
        initial_earning : ''	,
        duration : '',
    }
    
    
    const [error, setErros] = useState('');
    const [planForm, setPlanForm] = useState(initialLogin);

    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postPlan } = useMutation(

        async () => {
          return await apiClient.post(`/api/create-plan`, {
            name : planForm.name,	
            max_amount : planForm.max_amount,	
            min_amount : planForm.min_amount,	
            bonus_per_downline : planForm.bonus_per_downline,	
            number_of_downlines : planForm.number_of_downlines,	
            total_earning : planForm.total_earning,	
            initial_earning : planForm.initial_earning,	
            duration : planForm.duration,
            amount_per_hour: planForm.amount_per_hour


          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );
      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postPlan();
      } 

      useEffect(() => {
        getplans();
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                        CREATE INVESTMENT PLANS
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                        Fill the form below to create investment plans
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="Plan Name">Plan Name</InputLabel>
                            {(error !== '' && error.name) ?
                            <OutlinedInput
                            error
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label="Plan Name"
                            helperText={error.name}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="name"
                            name="name"
                            type={'text'}
                            label="Plan Name"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.name}
                            </Typography>
                            
                        </FormControl>

                        

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="min_amount">Min Amount</InputLabel>
                            {(error !== '' && error.min_amount) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="min_amount"
                            type={'number'}
                            name="min_amount"
                            label="Min Amount"
                            helperText={error.min_amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="min_amount"
                            type={'number'}
                            name="min_amount"
                            label="Min Amount"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.min_amount}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="max_amount">Max Amount</InputLabel>
                            {(error !== '' && error.max_amount) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="max_amount"
                            type={'number'}
                            name="max_amount"
                            label="Max Amount"
                            helperText={error.max_amount}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="max_amount"
                            type={'number'}
                            name="max_amount"
                            label="Max Amount"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.max_amount}
                            </Typography>
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                          <InputLabel htmlFor="amount_per_hour">Amount Per Hour</InputLabel>
                          {(error !== '' && error.amount_per_hour) ?

                          <OutlinedInput
                          onChange={onChange}
                          error
                          id="amount_per_hour"
                          type={'number'}
                          name="amount_per_hour"
                          label="Amount Per Hour"
                          helperText={error.amount_per_hour}
                          />
                          :
                          <OutlinedInput
                          onChange={onChange}
                          id="amount_per_hour"
                          type={'number'}
                          name="amount_per_hour"
                          label="Amount Per Hour"
                          />
                          }
                          <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                          {error !== '' && error.amount_per_hour}
                          </Typography>
                      </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="number_of_downlines">Number Of Downlines</InputLabel>
                            {(error !== '' && error.number_of_downlines) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="number_of_downlines"
                            type={'number'}
                            name="number_of_downlines"
                            label="Number Of Downlines"
                            helperText={error.number_of_downlines}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="number_of_downlines"
                            type={'number'}
                            name="number_of_downlines"
                            label="Number Of Downlines"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.number_of_downlines}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="bonus_per_downline">Bonus Per Downline</InputLabel>
                            {(error !== '' && error.bonus_per_downline) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="bonus_per_downline"
                            type={'number'}
                            name="bonus_per_downline"
                            label="Bonus Per Downline"
                            helperText={error.bonus_per_downline}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="bonus_per_downline"
                            type={'number'}
                            name="bonus_per_downline"
                            label="Bonus Per Downline"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.bonus_per_downline}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="total_earning">Total Earning</InputLabel>
                            {(error !== '' && error.total_earning) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="total_earning"
                            type={'number'}
                            name="total_earning"
                            label="Total Earning"
                            helperText={error.total_earning}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="total_earning"
                            type={'number'}
                            name="total_earning"
                            label="Total Earning"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.total_earning}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="initial_earning">Initial Earning</InputLabel>
                            {(error !== '' && error.initial_earning) ?

                            <OutlinedInput
                            onChange={onChange}
                            error
                            id="initial_earning"
                            type={'number'}
                            name="initial_earning"
                            label="Initial Earning"
                            helperText={error.initial_earning}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            id="initial_earning"
                            type={'number'}
                            name="initial_earning"
                            label="Initial Earning"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.initial_earning}
                            </Typography>
                            
                        </FormControl>

                        <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
                            <InputLabel htmlFor="duration">Duration</InputLabel>
                            {(error !== '' && error.duration) ? 
                            <OutlinedInput
                            error
                            onChange={onChange}
                            minRows={2} multiline
                            id="duration"
                            name="duration"
                            type={'text'}
                            label="Duration"
                            helperText={error.duration}
                            />
                            :
                            <OutlinedInput
                            onChange={onChange}
                            minRows={2} multiline
                            id="duration"
                            name="duration"
                            type={'text'}
                            label="Duration"
                            />
                            }
                            <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                            {error !== '' && error.duration}
                            </Typography>
                        </FormControl>
                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Creating...' : 'Create Plan'}</Button>
                    </CardActions>
                </Card>
            </Grid>
        </Grid>
    </div>
  )
}

export default CreatePlans
