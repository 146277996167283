import React, {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setFundingBal, setFundings, setSubBalance, setReferralBalance, setWithdrawableBalance } from '../../components/Wallet/walletSlice';

export const LoadFundings = () => {
  const dispatch = useDispatch();

    const { isLoading: isLoadingFundings, refetch: getFundings } = useQuery(
        "fundings",
        async () => {
          return await apiClient.get("/api/view-fundings");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                dispatch(setFundings(res.data.fundings))
                dispatch(setFundingBal(res.data.balance))                
                dispatch(setSubBalance(res.data.subscriptionBalance))
                dispatch(setReferralBalance(res.data.referralBalance))  
                dispatch(setWithdrawableBalance(res.data.withdrawableBalance))  
                              
                const event = new Event('updatedStorage');
                window.dispatchEvent(event);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        getFundings();
      }, []);
 
}

export default LoadFundings
