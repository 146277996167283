import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    earning_rates: JSON.parse(localStorage.getItem('earning_rates')) || [],
}

export const rateSlice = createSlice({
    name: 'earning_rates',
    initialState,
    reducers: {
        setRates: (state, action) =>{
            state.earning_rates = action.payload;  
            localStorage.setItem('earning_rates', JSON.stringify(state.earning_rates));
        }
    }
})

export const {setRates} = rateSlice.actions;
export default rateSlice.reducer;