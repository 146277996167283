import React, {useEffect} from 'react'
import apiClient from '../http-common';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { setRates } from './rateSlice';
export const GetRates = () => {
  const dispatch = useDispatch();

    const { isLoading: isloadingCoins, refetch: fetchCoins } = useQuery(
        "get-rate",
        async () => {
          return await apiClient.get("/api/get-rate");
        },
        {
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
              dispatch(setRates(res.data.analysis))
              
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      useEffect(() => {
        fetchCoins();
      }, []);
 
}

export default GetRates
